import React, { useEffect, useState } from 'react';
import './App.css';
import Login from '@/pages/Login'
import BasicLayout from '@/components/basicLayout.jsx'
import Auth from '@/utils/auth'

const App = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // checkIsLogin()
    try {
      let uData = Auth.get()
      setUserData(uData)
    } catch (error) {
      // todo
    }
  }, [])


  return <div>
    {
      userData ? <BasicLayout /> : (
        // 登录界面
        <Login />
      )
    }
  </div>
}

export default App;