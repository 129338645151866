import request from '@/utils/request';

export async function ActionGetlist(params) {
  return request('downloads/list', {
    method: 'GET',
    params,
  });
}

export async function ActionGetall(params) {
  return request('downloads/statistics/list', {
    method: 'GET',
    params,
  });
}

export async function ActionGetstatistics(params) {
  return request('downloads/statistics', {
    method: 'GET',
    params,
  });
}

export async function ActionGettotalversion(params) {
  return request('downloads/totalversion', {
    method: 'GET',
    params,
  });
}