import { extend } from 'umi-request';
import { notification } from 'antd';
import { getFullurl, getFullurlWithoutApi } from './utils';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '没有操作的权限',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * 异常处理程序
 */
const errorHandler = (error) => {
  const { response } = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    notification.error({
      message: `请求错误 ${status}: ${url}`,
      description: errorText,
    });
  } else if (!response) {
    notification.error({
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }
  return response;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  errorHandler,
  // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
});

// 对api连接修改
request.interceptors.request.use((url, options) => {
  const nurl = options.withoutApi ? getFullurlWithoutApi(url) : getFullurl(url);
  return {
    url: nurl,
    options,
  };
});

// 提前对响应做异常处理
request.interceptors.response.use(async (response, options) => {
  let result;
  const res = await response.clone().json();
  if (!res.success) {
    if (res.data.status === '404' ||res.data.status === 404 ) {
      // 404单独处理
      result = res.data;
    } else {
      // 界面报错处理
      notification.error({
        description: `${res.data.message || '未知错误'} 错误代码：${
          res.data.code || res.data.status || '未知'
        }`,
        message: '出错了',
      });
    }
  } else {
    result = res.data;
  }

  return result;
});

export default request;
