// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get() {
    return JSON.parse(localStorage.getItem('piestore-admin-auth'));
  },
  save(user) {
    localStorage.setItem('piestore-admin-auth', JSON.stringify(user));
  },
  clear() {
    localStorage.clear();
  },
};
