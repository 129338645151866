import Home from '@/router/home';
import styled from 'styled-components';
import Auth from '@/utils/auth';
import { useEffect ,useState } from 'react';
import { Dropdown } from 'antd';

const Header = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
`

const HeaderContent = styled.div`
    flex: 1;
    height: 100%;
    background-color: #122b40;
    background-image: url('assets/PieCloudDB_Logo.png');
    background-repeat: no-repeat;
    background-position: 12% 50%;
    background-size: 45%;
    position: relative;
`

const HeaderContentWord = styled.div`
    position: absolute;
    left: 55%;
    bottom: 25%;
    color: #006EF0;
`

const HeaderWrap = styled.div`
    flex: 5;
    border-bottom: 1px solid #f0f0f0;
    position: relative;
`

const HeaderIconContent = styled.div`
    position: absolute;
    right: 3%;
    top: 24%;

    &:hover{
        cursor: pointer;
    }
`

const HeaderIcon = styled.div`
    width: 32px;
    height: 32px;
    background-image: url('assets/user_admin.svg');
    background-repeat: no-repeat;
`

const HeaderWrapUser = styled.div`
    position: absolute;
    right: 7%;
    top: 36%;
    display: flex;
`

const Body = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
`

const BodySide =styled.div`
    flex: 1;
    height: 100%;
    color: #ffffff;
    background-color: #122b40;
`

const BodySideLink =styled.a`
    margin: 36px;
    padding-left:36px;
    text-decoration: none;
    color: #ffffff;
    width: 100%;

    &:hover{
        color: #006EF0;
    }
`

const BodySideWrap =styled.div`
    margin: 0 36px;
    padding-left: 18%;
    font-size: 20px;
    line-height: 2;
    border-radius: 5px;
    background-image: ${props => props.img ? `url(${props.img})` : 'initial'};
    background-repeat: no-repeat;
    background-position: 8% 50%;
    background-size: 10%;

    &:hover{
        background-color: #ffffff;
        background-image: ${props => props.hover ? `url(${props.hover})` : 'initial'};
    }
`

const BodyContent =styled.div`
    flex: 5;
`

const BasicLayout = () => {
    const [isLogout, setIsLogout] = useState(false);

    useEffect(() => {
        if(isLogout){
          window.location.reload();
        }
      }, [isLogout])

    let uData = Auth.get()

    const clearLocalStorage = () => {
        // 清除所有本地存储数据
        localStorage.clear();
        setIsLogout(true);
    };

    const items = [
        {
            key: '1',
            label: (
                <a onClick={clearLocalStorage}>
                    用户登出
                </a>
            ),
        }
    ]

    return (
        <div>
            <Header>
                <HeaderContent>
                    <HeaderContentWord>社区版</HeaderContentWord>
                </HeaderContent>
                <HeaderWrap>
                    <HeaderIconContent>
                        <Dropdown
                            menu={{
                            items,
                            }}
                            placement="bottom"
                            arrow
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <HeaderIcon/>
                            </a>
                        </Dropdown>
                    </HeaderIconContent>

                    { uData && uData.name ?
                        <HeaderWrapUser>
                            您好：{uData.name}
                        </HeaderWrapUser>
                        : null
                    }
                </HeaderWrap>
            </Header>
            <Body>
                <BodySide>
                    <BodySideLink href='/'>
                        <BodySideWrap  img={'assets/user_01.svg'} hover = {'assets/user_02.svg'}>
                            用户管理
                        </BodySideWrap>
                    </BodySideLink>
                    <BodySideLink href='/statistics'>
                        <BodySideWrap  img={'assets/data_01.svg'} hover = {'assets/data_02.svg'}>
                            统计数量
                        </BodySideWrap>
                    </BodySideLink>
                </BodySide>
                <BodyContent>
                    <Home />
                </BodyContent>
            </Body>

        </div>
    )
}

export default BasicLayout;