import styled from 'styled-components';
import { DatePicker, Space, Button, Input, Form, Pagination, Select, Row, Col } from 'antd';
import { ActionGetall, ActionGettotalversion } from '@/services/download';
import { useEffect, useState } from 'react';
import ExportJsonExcel from 'js-export-excel';
import { DownloadOutlined } from '@ant-design/icons';

const dayjs = require('dayjs')
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const { RangePicker } = DatePicker;
const { Option } = Select;

const formStyle = {
    maxWidth: 'none',
    padding: '2vw 0 0 3.5vw',
};

const Header = styled.div`
    margin: 1vw 0 0 2vw;
    font-size: 24px;
    font-weight: bold;
`

const Body = styled.div`
    display: flex;
    margin: 0 2vw;
`

const BodyContent = styled.div`
    flex: 1;
    height: 100%;
    text-align: center;
    color: #7E8491;
    font-size: 1vw;
`

const ContentTitle = styled.div`
    width: 100%;
    margin-top: 2vw;
    height: 2.5vw;
    border-bottom: 1px solid #E7E7E7;
`

const ContentDetail = styled.div`
    width: 100%;
    margin-top: 1vw;
    height: 2vw;
    line-height: 1;
    border-bottom: 1px solid #E7E7E7;
`

const Bottom = styled.div`
    width: 100%;
    margin-top: 1vw;
    height: 2vw;
`

const BottomTotal = styled.div`
    margin-left: 3vw;
`

const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a style={{marginRight: 12}}>上一页</a>;
    }
    if (type === 'next') {
      return <a style={{marginLeft: 12}}>下一页</a>;
    }
    return originalElement;
};

const Home = () => {
    const [form] = Form.useForm();
    const [postData, setPostData] = useState(null);
    const [versionTotalData, setVersionTotalData] = useState(null);
    const [condation, setCondation] = useState({});
    const [current, setCurrent] = useState(1); // 当前分页位置
    const [paginationData, setPaginationData] = useState({
        current: 1,
        page_number: 1,
        page_size: 1,
        total: 1
    });

    useEffect(() => {
      getDownloadData();
      if(!versionTotalData){
        getVersions()
      }
    }, [current, condation]);

    const getDownloadData = async () => {
      const res = await ActionGetall({current, ...condation});

      if(res){
          setPostData(res.data)
          setPaginationData(res.pagination)
      }
    }

    const getVersions = async () => {
      const version = await ActionGettotalversion();
      if(version){
        setVersionTotalData(version)
      }
    }

    const searchSubmit = async (values) => {
      setCurrent(1)
      setCondation({
        startDate: values.dates? values.dates[0].utc().format('YYYY-MM-DD HH:mm:ss') : null,
        endDate: values.dates? values.dates[1].utc().format('YYYY-MM-DD HH:mm:ss') : null,
        versionData: values?.version || null,
        mobileData: values?.mobile || null,
      })
    }

    const changePagination = (page) => {
      setCurrent(page)
    }

  //导出表格
  const downloadFileToExcel = async () => {
    var option={};
    let dataTable = [];

    const res = await ActionGetall({current, ...condation, download_data: 'true'});

    if (res) {
      const downloadData = res.data
      downloadData.map((item)=>{
        let obj = {
          mobile: item.mobile,
          version_name: `PieCloudDB ` + item.version_name,
          create_time: item.create_time.substr(0,4) + `年` + item.create_time.substr(5,2) + `月` + item.create_time.substr(8,2) + `日` + item.create_time.substr(10,6),
          update_time: item.update_time.substr(0,4) + `年` + item.update_time.substr(5,2) + `月` + item.update_time.substr(8,2) + `日` + item.update_time.substr(10,6),
          reg_time: item.reg_time.substr(0,4) + `年` + item.reg_time.substr(5,2) + `月` + item.reg_time.substr(8,2) + `日` + item.reg_time.substr(10,6),
          ip_addr: JSON.parse(item.access_url).ip_addr,
        }
        dataTable.push(obj);
        return dataTable
      })
    }
    option.fileName = '用户详细信息' + dayjs().format('YYYY-MM-DD')
    option.datas=[
      {
        sheetData:dataTable,
        sheetName:'用户管理',
        sheetFilter:['mobile','version_name','create_time','update_time','reg_time','ip_addr'],
        sheetHeader:['手机号','下载版本','下载时间','最后登录时间','注册时间','IP地址'],
      }
    ];
    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

    return (
    <div>
        <Header>用户管理</Header>
        <div style={{margin: '0 12px'}}>
          <Form
            form={form}
            style={formStyle}
            onFinish={searchSubmit}
          >
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item
                label={'下载时间'}
                name={'dates'}
                rules={[
                  {
                    type: 'array',
                    message: 'Please select time!',
                  },
                ]}
              >
                <RangePicker showTime style={{width: '100%'}}/>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name={'version'}
                label={'下载版本'}
                rules={[
                  {
                    message: 'Select version!',
                  },
                ]}
              >
                <Select placeholder="版本下载">
                  {
                    versionTotalData ? versionTotalData.map(item => {
                        return <Option key={`version-item-${item.slug}`} value={item.slug}>{item.slug}</Option>
                    }) : null
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label='手机号'
                name="mobile"
                rules={[
                  { message: '请输入手机号'},
                  { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的电话号码' }]
                }
              >
                <Input placeholder="搜索手机号"/>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Space>
                <Button type="primary" htmlType="submit">
                    查询
                </Button>
                <Button
                    onClick={() => {
                        form.resetFields();
                        setCondation({})
                    }}
                    >
                    重置
                </Button>
              </Space>
            </Col>
            <Col>
              <Button type="primary" htmlType="subout" onClick={downloadFileToExcel}>
                <DownloadOutlined />
                下载名单
              </Button>
            </Col>
            </Row>
          </Form>
        </div>
        <Body>
            <BodyContent>
                <ContentTitle>手机号</ContentTitle>
            </BodyContent>
            <BodyContent>
                <ContentTitle>下载版本</ContentTitle>
            </BodyContent>
            <BodyContent>
                <ContentTitle>下载时间</ContentTitle>
            </BodyContent>
            <BodyContent>
                <ContentTitle>最后登陆时间</ContentTitle>
            </BodyContent>
            <BodyContent>
                <ContentTitle>注册时间</ContentTitle>
            </BodyContent>
            <BodyContent>
                <ContentTitle>IP地址</ContentTitle>
            </BodyContent>
        </Body>
        <div>
            {
              postData ? postData.map((item,idx) => {
                return (
                  <div key={`item-${idx}`} style={{display: 'flex',margin: '0 2vw'}}>
                    <BodyContent>
                        <ContentDetail style={{color: '#006CFF'}}><u>{item.mobile}</u></ContentDetail>
                    </BodyContent>
                    <BodyContent>
                        <ContentDetail>PieCloudDB {item.version_name}</ContentDetail>
                    </BodyContent>
                    <BodyContent>
                        <ContentDetail>{item.create_time.substr(0,4)}年{item.create_time.substr(5,2)}月{item.create_time.substr(8,2)}日{item.create_time.substr(10,6)}</ContentDetail>
                    </BodyContent>
                    <BodyContent>
                        <ContentDetail>{item.update_time.substr(0,4)}年{item.update_time.substr(5,2)}月{item.update_time.substr(8,2)}日{item.update_time.substr(10,6)}</ContentDetail>
                    </BodyContent>
                    <BodyContent>
                        <ContentDetail>{item.reg_time.substr(0,4)}年{item.reg_time.substr(5,2)}月{item.reg_time.substr(8,2)}日{item.reg_time.substr(10,6)}</ContentDetail>
                    </BodyContent>
                    <BodyContent>
                        <ContentDetail>{JSON.parse(item.access_url).ip_addr}</ContentDetail>
                    </BodyContent>
                  </div>
                )
              }) : null
            }
            <Bottom>
                <BottomTotal>
                  共计 <span style={{color: '#006CFF',fontWeight: '500'}}>
                      {paginationData.total}
                      </span> 条
                </BottomTotal>
                <Pagination style={{textAlign: 'center', marginBottom: 50}} size="small" current={current} total={paginationData.total} defaultPageSize={10} itemRender={itemRender} onChange={changePagination} showSizeChanger = {false}/>
            </Bottom>

        </div>
    </div>

    )
}

export default Home;