import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { actionLogin } from '@/services/login';
import styled from 'styled-components'
import auth from "@/utils/auth"

const Total = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

const TopIcon = styled.img`
  width: 20vw;
  height: 15vw;
  margin: 0 auto;
`

const Container = styled.div`
  width: 100%;
`

const ButtonContent = styled.div`
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Login = () => {
    const [form] = Form.useForm();
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        if(isLogin){
          window.location.reload();
        }
      }, [isLogin])

    const onFinish = (values) => {
        console.log('Success:', values);
        auth.save(values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleSubmit = async (values) => {
        const {log_name, log_password} = values

        const res = await actionLogin({log_name, log_password, 'access-from': 'admin_piestore'});

        if(res){
          if(res.access_token){
            form.resetFields()

            try {
              auth.save(res)
              message.success('验证成功')
              setIsLogin(true)

            } catch (error) {
              message.error('出错了,请稍后再试')
            }
          }
          else {
            // 错误提示
            message.error(res)
          }
        }
    }

    return (
        <Total>
          <TopIcon alt="logo" src="/logo.svg"/>
          <Form
          name="basic"
          labelCol={{
          span: 8,
          }}
          wrapperCol={{
          span: 16,
          }}
          style={{
          width: 600,
          margin: '0 auto',
          }}
          initialValues={{
          remember: true,
          }}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          >
            <Container>
              <Form.Item
                  label="用户名"
                  name="log_name"
                  rules={[
                  {
                      required: true,
                      message: '请输入您的用户名!',
                  },
                  ]}
              >
                  <div style={{width: '60%'}}>
                  <Input />
                  </div>

              </Form.Item>

              <Form.Item
                  label="密码"
                  name="log_password"
                  rules={[
                  {
                      required: true,
                      message: '请输入您的密码!',
                  },
                  ]}
              >
                  <div style={{width: '60%'}}>
                  <Input.Password />
                  </div>
              </Form.Item>

              <Form.Item
                  name="remember"
                  valuePropName="checked"
                  wrapperCol={{
                  offset: 8,
                  span: 16,
                  }}
              >
                  <Checkbox>记住我的信息</Checkbox>
              </Form.Item>

              <Form.Item
                  wrapperCol={{
                  offset: 10,
                  span: 16,
                  }}
              >
                <Button type="primary" htmlType="submit" style={{height: '100%'}}>
                  <ButtonContent>
                    登录
                  </ButtonContent>
                </Button>
              </Form.Item>
            </Container>
          </Form>
        </Total>
    )
}

export default Login;