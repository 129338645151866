import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ActionGetstatistics } from '@/services/download';

const Body = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
`

const BodyLastTime = styled.div`
    margin: 2vw 0 0 1.5vw;
`

const BodyTitle = styled.p`
    margin: 2vw 0 0 3vw;
    font-size: 20px;
    font-weight: 500;
`

const BodyContent = styled.div`
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const ContentTitle = styled.div`
    font-size: 20px;
    color: grey;
    margin-top: 2vw;
    border-bottom: 1px grey solid;
`

const ContentDetail = styled.div`
    font-size: 20px;
    margin-top: 2vw;
    font-weight: 500;
    line-height: 1;
`
const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');

const Home = () => {
    const [totalsalesData, setTotalsalesData] = useState({
        curr_view: 0,
        yest_view: 0,
        seven_view: 0,
        month_view: 0,
        year_view: 0,
        total_view: 0
    });

    useEffect(() => {
        getDownloadData();
    }, []);

    const getDownloadData = async () => {
        const res = await ActionGetstatistics();
        if(res){
            setTotalsalesData(res);
        }
    }

    return (
    <Body>
        <BodyLastTime>截止时间：{year}年{month}月{day}日</BodyLastTime>
        <BodyTitle>总访问次数</BodyTitle>
        <div style={{display: 'flex', marginLeft: '10vw'}}>
            <BodyContent>
                <ContentTitle>时间:</ContentTitle>
                <ContentDetail>今天</ContentDetail>
                <ContentDetail>昨天</ContentDetail>
                <ContentDetail>过去1周</ContentDetail>
                <ContentDetail>过去1个月</ContentDetail>
                <ContentDetail>过去1年</ContentDetail>
                <ContentDetail>全部</ContentDetail>
            </BodyContent>
            <BodyContent>
                <ContentTitle>访客数</ContentTitle>
                <ContentDetail>{totalsalesData.curr_view}</ContentDetail>
                <ContentDetail>{totalsalesData.yest_view}</ContentDetail>
                <ContentDetail>{totalsalesData.week_view}</ContentDetail>
                <ContentDetail>{totalsalesData.month_view}</ContentDetail>
                <ContentDetail>{totalsalesData.year_view}</ContentDetail>
                <ContentDetail>{totalsalesData.total_view}</ContentDetail>
            </BodyContent>
        </div>

    </Body>
    )
}

export default Home;