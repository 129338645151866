import auth from './auth';
import config from '@/config/config';

export const getFullurl = (url, needToken = true) => {
  let nurl = '/api/' + url;

  if (needToken) {
    try {
      const { access_token } = auth.get();
      nurl += `?access-token=${access_token}`;
    } catch {}
  }

  return nurl;
};
export const getFullurlWithoutApi = (url, needToken = true) => {
  let nurl = `${config.backend_url}/` + url;

  if (needToken) {
    try {
      const { access_token } = auth.get();
      nurl += `?access-token=${access_token}`;
    } catch {}
  }

  return nurl;
};

export const checkExpired = (token_expired_in) => {
  var now = new Date().getTime();
  var expired_time = new Date(token_expired_in).getTime();
  if (now > expired_time) {
    return true;
  } else {
    return false;
  }
};


export const getAllurl = (url, needToken = true) => {
  let nurl = 'http://localhost:8091/' + url;
  // let nurl = 'https://download.openpie.com/' + url;

  if (needToken) {
    try {
      const { access_token } = auth.get();
      nurl += `?access-token=${access_token}`;
    } catch {}
  }

  return nurl;
};

/**
 * link函数 url带上 ‘/’
 * @param {*} url 
 * @returns 
 */
export const linkTo = (url) => {
  return `${config.frontend_url}${url}`
};
