import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import NotFound from '@/pages/404'
import HomePage from '@/pages/home'
import Statistics from '@/pages/statistics'

const Home = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/statistics" element={<Statistics />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Home;